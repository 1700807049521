<template>
    <div class="vld-parent">
        <loading
            :active.sync="isLoading"
            :can-cancel="true"
            :height="80"
            :width="80"
            :is-full-page="fullPage"></loading>
    </div>
</template>

<script>
// Import component
import Loading from 'vue-loading-overlay';
// Import stylesheet
import 'vue-loading-overlay/dist/vue-loading.css';

    export default {
        name: 'Payment',
        components: {
            Loading
        },
        data() {
            return {
                isLoading: false,
                fullPage: true
            }
        },
        mounted() {
            this.isLoading = true;
            this.$axios.get('api/getPaymentStatus/' + this.$route.params.id).then(res => {
                console.log(res.data)
                if(res.data.status === 'paid'){
                    // alert('paid')
                    // window.location.replace('https://backend.brasseriedenanker.be/storage/app/public/documents/' + res.data.doc + '.pdf')
                    this.isLoading = false
                    this.$router.push({path: '/home'})
                }else{
                    this.isLoading = false
                    alert('Betaling geannuleerd.')
                    this.$router.push({path: '/home'})
                }
            }).catch(err => {
                console.log(err)
            })
        }
    }
</script>

<style scoped>

</style>
